import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ImageField from '@e2grnd/ui-core/dist/containers/ImageField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

import { geometries } from '../geometries'
import * as images from '../images'

const Step1 = ({ shellType, style }) => (
  <div className="fl w-100 w-75-m w-50-l pa3">
    <ImageField
      style={style}
      images={images}
      imageDescriptors={geometries}
      name="shellType"
      value={shellType}
      required
    />
    <Submit label="Next" />
  </div>
)

Step1.propTypes = {
  shellType: PropTypes.string,
  style: PropTypes.object,
}

const mapStateToProps = (state) => ({
  shellType: state.workflow.fields.shellType,
  style: state.ui.style,
})

export default connect(mapStateToProps, null)(Step1)